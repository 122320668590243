// extracted by mini-css-extract-plugin
export var active = "styles-module--active--1b4lO";
export var bottom = "styles-module--bottom--22cVb";
export var card = "styles-module--card--1sfpO";
export var cards = "styles-module--cards--2gKjN";
export var cta = "styles-module--cta--OovgP";
export var description = "styles-module--description--2Y9BP";
export var designElement = "styles-module--designElement--3r0uY";
export var heading = "styles-module--heading--2IqY9";
export var icon = "styles-module--icon--Fzhm1";
export var org = "styles-module--org--2kpeX";
export var sectionWrap = "styles-module--sectionWrap--DOeER";
export var title = "styles-module--title--34QFV";
export var top = "styles-module--top--3OffS";