import {StyleExtractor} from "@kubric/utils";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import Slider from "react-slick";

import HomeDesignElement from "../../../../assets/HomeDesignElement.svg";
import SanityRichText from "../../../commons/SanityRichText";
import {ArrowRightIcon} from "../../../icons/Arrows";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const Card = ({heading, image, hoveredImage, description, ctaText, ctaUrl}) => (
  <div className={styler.get("card")}>
    <div className={styler.get("iconWrapper")}>
      <GatsbyImage
        image={image?.image?.asset?.gatsbyImageData}
        objectFit='contain'
        alt={image?.alt}
        className={styler.get(["icon", "org"])}
      />
      <GatsbyImage
        image={hoveredImage?.image?.asset?.gatsbyImageData}
        objectFit='contain'
        alt={hoveredImage?.alt}
        className={styler.get(["icon", "active"])}
      />
    </div>
    <span className={styler.get("title")}>{heading}</span>
    <span className={styler.get("description")}>{description}</span>
    {ctaText && ctaUrl && (
      <OutboundLink
        className={styler.get("cta")}
        href={ctaUrl}
        target='_blank'
        rel='noopener noreferrer'
      >
        {ctaText}
      </OutboundLink>
    )}
  </div>
);

const MMReasons = ({data, isMobile, hideBorderSVG = false}) => {
  const {_rawHeading, cards: cardsData = []} = data ?? {};

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <ArrowRightIcon color='#CCCFD4' />,
    prevArrow: <ArrowRightIcon color='#CCCFD4' />,
  };

  return (
    <section
      className={styler.get("sectionWrap", [
        "full-bleed pb-12 px-4 md:px-0",
        !hideBorderSVG && "mt-8",
      ])}
    >
      {!hideBorderSVG && (
        <HomeDesignElement className={styler.get(["designElement", "top"])} />
      )}
      <h2 className={styler.get("heading")}>
        <SanityRichText blocks={_rawHeading} className='text-center' />
      </h2>
      <div className={styler.get("cards")}>
        {!isMobile ? (
          cardsData.map((data) => <Card {...data} key={data._key} />)
        ) : (
          <Slider {...settings}>
            {cardsData.map((data) => (
              <Card {...data} key={data._key} />
            ))}
          </Slider>
        )}
      </div>
    </section>
  );
};

export default MMReasons;
